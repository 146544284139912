.card {
  width: 100%;
  height: 300px;
  box-shadow: 2px 2px 15px rgba(73, 80, 87, 0.2);
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}
.card img {
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: #2a2b2e;
}
.card .details {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(120%);
  -webkit-transform: translateY(120%);
  -moz-transform: translateY(120%);
  -ms-transform: translateY(120%);
  -o-transform: translateY(120%);
  background: rgba(255, 255, 255, 0.801);
  color: #000;
  backdrop-filter: blur(5px);
  padding: 15px;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 0);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}
.card .details .name {
  text-transform: capitalize;
}
.card .details .name h3 {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 5px;
}
.card .details .name span {
  display: block;
  font-size: 16px;
  padding-bottom: 15px;
}
.card:hover .details {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
