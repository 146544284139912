@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 15px;
  background-color: #ffffff;
}

code {
    font-family: "Montserrat", sans-serif;
}

.ant-layout {
  background-color: #ffffff !important;
}
.ant-layout-header {
  background-color: #0b0d17 !important;
  height: 85px !important;
}

.ant-divider-vertical {
  margin: 0 30px 0 0 !important;
  height: 1.9em !important;
  border-left: 1.5px solid #a39d8a !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  display: none;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #0b0d17 !important;
}
.ant-menu-horizontal {
  line-height: 90px !important;
}
.ant-image-preview-operations-operation:not(:first-child) {
  display: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f3ca40 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 10rem !important;
}
.ant-tabs-ink-bar {
  background-color: #f3ca40 !important;
}
.ant-tabs-tab-btn {
  font-size: 20px !important;
  color: #0b0d17 !important;
}
.ant-layout-footer {
  padding: 0px !important;
}
.ant-carousel .slick-dots li button {
  background: #ffcc00 !important;
  width: 13px !important;
  height: 13px !important;
  border-radius: 50px !important;
}
.ant-collapse > .ant-collapse-item {
  margin-bottom: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 22px !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  font-size: 18px !important;
}
.react-multi-carousel-dot button {
  background-color: #ffcc00;
  border-color: #ffcc00;
}
.react-multi-carousel-list {
  padding-bottom: 60px;
}
.ant-timeline-item-head {
  width: 20px !important;
  height: 20px !important;
  background-color: #4a463e !important;
}
.ant-timeline-item-tail {
  left: 9.2px !important;
  border-left: 2px solid #4a463e !important;
}
.ant-tabs-tab {
  padding-right: 7rem !important;
}
@media screen and (max-width: 800px) {
  .ant-list-lg .ant-list-item {
    padding: 10px 5px !important;
  }
}
.ant-spin-dot-item {
  background-color: #ffffffff !important;
}


.ant-carousel .slick-next {
  right: 28px !important;
}

.ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  bottom: 20px;
  font-size: 35px;
  color: #ffcc00;
  background-color: #f5f5f5;
  border: 2px solid #e2dfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 12.5px 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}